@import "variables";

.wrapper {
  display: flex;
  flex-direction: column;
}

.text {
  width: 100%;

  @media screen and (max-width: 767px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
}

.heading {
  color: white;
  font-family: $font-secondary;
  text-transform: uppercase;
  margin: 0;
  overflow-wrap: anywhere;
}

.headingSmall {
  font-size: 18px;

  @media screen and (max-width: 767px) {
    font-size: 13px;
  }

  i {
    font-family: $font-primary;
  }
}

.headingLarge {
  margin-top: 8px;
  font-size: 32px;
  letter-spacing: -1px;
  line-height: 34px;
  
  @media screen and (min-width: 1025px) {
    font-size: 46px;
    line-height: 50px;
    letter-spacing: -2px;
  }
}

.disclaimer {
  font-size: 13px;
  font-family: $font-primary;
  font-weight: 400;
  margin: 8px 0 0;
}

.buttons {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
  &.center { 
    margin: 32px auto 0;
    @media screen and (max-width: 767px) {
      margin: 32px 0 0;
    }
  }
}

.secondaryButton {
  margin-left: 16px;
  @media screen and (max-width: 425px) {
    margin-left: 0;
    margin-top: 16px;
  }
}

.dark {
  background-color: var(--global--color-focus-tertiary) !important;
  color: white !important;
}

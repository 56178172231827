@import "variables";

.videoResponsive {
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  position: relative;
  background: center / contain no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  background-color: rgba(black, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  padding: 0;
  overflow: auto;
  z-index: $z-index-modal;

  @include not-mobile {
    justify-content: center;
    align-items: center;
  }
}

.modal {
  position: relative;
  padding: 0;
  background: white;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  width: 80%;
  height: 80%;

  @include mobile {
    width: 100%;
    height: 40%;
    min-width: 0;
    margin: auto;
  }
}

.iframeStyle {
  width: 100%;
  height: 100%;
}

.close {
  padding: 5px;
  cursor: pointer;
  background-image: url("https://www.datocms-assets.com/74813/1709080372-vector.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.closePlayerContainer {
  position: absolute;
  top: 20px;
  right: 10px;
  color: white;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.closePlayerText {
  font-family: "AktivGrotesk Ex Black Italic";
  font-size: 13px;
  margin-right: 10px;
}

.playYoutubeButton {
  position: absolute;
  height: 100px;
  width: 100px;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.5);
}

.circleWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bigCircle {
  cursor: pointer;
  position: absolute;
  width: 160px;
  height: 160px;
  border: 2px solid var(--global--color-gold);
  border-radius: 50%;
  transition: all 0.3s ease-in-out; 
  background-color: transparent;
  border-radius: 160px;
  @media screen and (max-width: 1024px) {
    width: 100px;
    height: 100px;
  }
}

.smallCircle {
  cursor: pointer;
  position: relative;
  width: 130px;
  height: 130px;
  background-color: white;
  border-radius: 50%;
  border: 4px solid var(--global--color-gold);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: border-width 0.3s ease-in-out;

  @media screen and (max-width: 1024px) {
    width: 81px;
    height: 81px;
  }
}

.playButton {
  background-image: url("https://www.datocms-assets.com/74813/1709076904-play.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-position-y: center;
  background-position-x: 55%;
  @media screen and (max-width: 1024px) {
    background-size: 40%;
  }
}

.circleWrapper:hover .bigCircle {
  transform: scale(0.8125);
  border-width: 6px;

}

.circleWrapper:hover .smallCircle {
  border-width: 6px;
}

.modalOpenImagesContainer {
  position: relative;
  cursor: pointer;
}

.modalOpenImagesContainer {
  position: relative;
  cursor: pointer;
}

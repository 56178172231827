.container {
  width: 100%;
  max-width: 100% !important;
  margin: 0 !important;
  height: 741px;
  @media screen and (max-width: 1440px) {
    height: 645px
  }

  @media screen and (max-width: 1024px) {
    height: 578px
  }

  @media screen and (max-width: 767px) {
    height: 832px;
  }

  @media screen and (max-width: 425px) {
    height: 665px;
  }
}

.slick-slider-custom {
  margin: 0;
  height: 741px;
  @media screen and (max-width: 1440px) {
    height: 645px
  }

  @media screen and (max-width: 1024px) {
    height: 578px
  }

  @media screen and (max-width: 767px) {
    height: 832px;
  }

  @media screen and (max-width: 425px) {
    height: 665px;
  }

  :global(.slick-dots) {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 1700px;
    height: 100px;
    z-index: 9;
    padding: 0 40px;
    gap: 24px;
    margin: auto;
    bottom: 100px;
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      bottom: 120px;
    }
    @media screen and (max-width: 1440px) {
      padding: 0 25px;
    }

    @media screen and (max-width: 767px) {
      bottom: 24px;
      height: 10px;
      gap: 8px;
      padding: 0 25px;
    }

    li {
      height: 10px;
      margin: 0;
      width: calc((100% - (24px * 4)) / 5);
      @media screen and (max-width: 767px) {
        flex: 1;
      }
    }
  }
}

.textSlider {
  :global(.slick-slide) {
    opacity: 0;
    transition: opacity 0.5s ease-in;
  }

  :global(.slick-active) {
    opacity: 1;
  }

  :global(.slick-cloned) {
    opacity: 0.3;
  }
}

.paging {
  height: 3px;
  background: #2C292580;
  &.activePage {
    background: #2C2925;
  }
  @media screen and (min-width: 768px) {
    margin-right: 8px;
  }
}

.active {
  height: 3px;
  background: #cf9c43;
  animation: progress 4s ease-in-out;
  -webkit-transition: progress 4s ease-in-out;
  -moz-transition: progress 4s ease-in-out;
}

.inActive {
  height: 3px;
}

.text {
  white-space: initial;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.8);
  text-align: left;
  flex: none;
  order: 1;
  align-self: stretch;
  &.dark {
    color: rgb(44, 41, 37, 0.8)
  }

  &.textWrapper {
    margin-top: 15px;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.activeText {
  color: #fff;
  opacity: 1 !important;
}

@keyframes progress {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
.container {
  width: 100%;
  max-width: 100% !important;
  height: 741px;
  margin: 0 !important;
  position: relative;
  align-items: center;
  background: center bottom / cover no-repeat;
  display: inline-block;
  @media screen and (max-width: 1440px) {
    height: 645px
  }

  @media screen and (max-width: 1024px) {
    height: 578px
  }

  @media screen and (max-width: 767px) {
    height: 832px;
  }

  @media screen and (max-width: 425px) {
    height: 665px;
  }
}

.container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.leftDarkGradient::before {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.05));
  @media screen and (max-width: 767px) {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.05));
  }
}

.leftLightGradient::before {
  background: linear-gradient(to right, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.05));
  @media screen and (max-width: 767px) {
    background: linear-gradient(to top, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.05));
  }
}

.rightDarkGradient::before {
  background: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.05));
  @media screen and (max-width: 767px) {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.05));
  }
}

.rightLightGradient::before {
  background: linear-gradient(to left, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.05));
  @media screen and (max-width: 767px) {
    background: linear-gradient(to top, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.05));
  }
}

.lightBlanket::before {
  background: rgba(255, 255, 255, 0.4);
}

.darkBlanket::before {
  background: rgba(0, 0, 0, 0.4);
}


.contentContainer {
  margin: auto;
  position: relative;
  max-width: 1700px;
  height: 90%;
  padding: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 2;
  &.center {
    justify-content: center;
    text-align: center;
  }

  &.rightText {
    flex-direction: row-reverse;
  }
  @media screen and (max-width: 1440px) {
    padding: 25px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 767px) {
    height: 100%;
    flex-direction: column-reverse !important;
  }
}

.textButtonWrapper {
  width: 49%;
  @media screen and (min-width: 768px) {
    height: 100%;
    display: flex;
    align-items: center;
    &.center {
      justify-content: center;
    }
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.mediaContainer {
  width: 47%;
  height: inherit;
  align-content: center;
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 20px;
  }
}

.image {
  width: 100%;
  height: 0;
  padding-top: 75%;
  background: center / contain no-repeat;
}
